<template>
  <div class="sale-all-skin-container">
    <SharedKitButton
      :is-disabled="isDropSold || disabled"
      :color-preset="ColorPresets.ACCENT"
      :size-preset="actionButtonsSize"
      :type-preset="TypePresets.RECTANGLE"
      class="summary-container__button"
      @click.once="handleSellAllDrop"
    >
      <I18nT keypath="battles.creationUpperFilter.sellAllSkinsFor">
        <template #inventorySum>
          <BaseCurrency> {{ getValueRoundedByCurrency(sum ?? 0) }} </BaseCurrency>
        </template>
      </I18nT>
    </SharedKitButton>
  </div>
</template>

<script setup lang="ts">
import type { ISaleAllSkinButtonEmits, ISaleAllSkinButtonProps } from '../SaleAllSkinButton/SaleAllSkinButton.types';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

withDefaults(defineProps<ISaleAllSkinButtonProps>(), {
  disabled: false,
  sum: 0,
});
const emit = defineEmits<ISaleAllSkinButtonEmits>();

const isDropSold = ref(false);

const handleSellAllDrop = () => {
  isDropSold.value = true;
  emit('sell-all');
};

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));
</script>

<style src="./SaleAllSkinButton.scss" scoped lang="scss"></style>
