<template>
  <div class="not-item-wrapper">
    <UiImage class="not-item-image" src="/static/shared/no-items.png" />

    <SharedText
      :weight="FontWeights.BOLD"
      :size="changeXL(FontSizes.LARGE_2XL, FontSizes.LARGE)"
      :color="Colors.NEUTRAL.WHITE"
      >{{ $t('quickSale.noItems') }}</SharedText
    >
  </div>
</template>

<script setup lang="ts">
import { Colors } from '~/constants/colors.constants';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';

const changeXL = GlobalUtils.Media.changeByMedia('xl');
</script>

<style src="./NotActiveItems.style.scss" lang="scss" scoped></style>
