<template>
  <BaseModal v-model="isOpen" class="qs-modal">
    <QuickSaleContent
      :all-inventory="allInventory"
      :is-pending="isPending"
      :is-pending-sell="isSellInventoryLoading"
      :total-items="totalItems"
      :quantity-selected-items="quantitySelectedItems"
      :total-sum-selected-items="totalSumSelectedItems"
      :total-sum-items="totalPrice"
      @redirect-to-open="handlerRedirect"
      @selected-toggle="(id: number) => handlerSelectedInventory(id)"
      @selected-all="handlerSelectedAllItem"
      @sell-selected-items="handlerSellInventory"
      @cleared-all="handlerRemoveAllFromSelected"
      @fetch-item="qsInventory.fetchInventory"
      @sell-all-inventory="handlerSellAllInventory"
    />
  </BaseModal>
</template>

<script setup lang="ts">
import QuickSaleContent from '../../components/QuickSaleContent/QuickSaleContent.vue';
import { useQsInventory } from '../../store/qs.inventory';
import { useQsPopupStore } from '../../store/qsPopup.store';
import type { IQuickSaleControllerEmits } from './QuickSaleController.types';
import { QuickSaleEvents } from '~/repository/amplitude/events/quickSale';

const emits = defineEmits<IQuickSaleControllerEmits>();
const qsInventory = useQsInventory();

const {
  allInventory,
  isPending,
  selectedInventory,
  totalItems,
  quantitySelectedItems,
  totalSumSelectedItems,
  totalPrice,
  isSellInventoryLoading,
} = storeToRefs(qsInventory);

const qsPopupStore = useQsPopupStore();
const { isOpen } = storeToRefs(qsPopupStore);

watch(isOpen, (value) => {
  value ? qsInventory.fetchInventory() : qsInventory.resetInventory();
});

const handlerSelectedInventory = (id: number) => {
  const isSelected = selectedInventory.value.some((el) => el.id === id);
  isSelected ? qsInventory.removeItemFromSelected(id) : qsInventory.selectedItem(id);
};

const handlerRedirect = () => {
  qsPopupStore.close();

  QuickSaleEvents.toCasesClicked();

  qsInventory.redirectToOpenCase();
};

const handlerSellInventory = () => {
  qsInventory.sellInventory();

  QuickSaleEvents.itemsSold({ 'Item Count': selectedInventory.value.length, 'Item Sum': totalSumSelectedItems.value });
};

const handlerSellAllInventory = () => {
  qsInventory.sellAllInventory();

  QuickSaleEvents.allItemsSold({ 'Item Count': totalItems.value, 'Item Sum': +totalPrice.value });
  emits('sellAllTrigger');
  reloadNuxtApp();
};

const handlerRemoveAllFromSelected = () => {
  qsInventory.removeAllFromSelected();
  const countNumber = qsInventory.allInventory;

  QuickSaleEvents.resetAllClicked({ 'Item Count': countNumber.length });
};

const handlerSelectedAllItem = () => {
  qsInventory.selectedAllItem();
  const countNumber = qsInventory.allInventory;

  QuickSaleEvents.chooseAllClicked({ 'Item Count': countNumber.length });
};
</script>
