<template>
  <div class="price">
    <UiImage v-if="priceWithCoins.priceIcon" class="price-image" :src="priceWithCoins.priceIcon" />
    <div class="price-content">
      <h2 class="price-title">
        <BaseCurrency class="price-text">
          {{ getValueRoundedByCurrency(priceWithCoins.price) }}
        </BaseCurrency>
        <template v-if="props.priceWithCoins.coinPrice">
          <div class="price-text">+</div>
          <UiImage class="price-coin-icon" :src="props.priceWithCoins.coinIcon" />
          <div class="price-text">{{ priceWithCoins.coinPrice }}</div>
        </template>
      </h2>
      <div class="price-price">{{ $t('lostLocales.onBalance') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IPriceComponentProps } from './PriceComponent.types';

const props = defineProps<IPriceComponentProps>();
</script>

<style src="./PriceComponent.style.scss" lang="scss" scoped></style>
