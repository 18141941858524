import { EHeaderMenuItems, EStatisticItemVariants, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'dota-global',
    game: 'dota',
    theme: 'gray',
    defaultCurrency: 'USD',
    siteCurrency: 'GOLD',
    auth: {
      isCheckboxesSelectedByDefault: false,
      variants: ['steam', 'google'],
    },
    cases: {
      showButtons: false,
      priceProject: 'DOTABOOM',
      siteName: 'DotaBoom',
    },
    contacts: {
      adMail: 'media@dotaboom.gg',
      supportMail: 'support@dotaboom.gg',
      partnerMail: 'partner@dotaboom.gg',
      hideVk: true,
    },
    daily: {
      hideFromTop: true,
      hideTimer: true,
    },
    drop: {
      hideShare: true,
    },
    header: {
      bottomMenu: {
        left: ['home', 'battles'],
        right: ['daily', 'menu'],
      },
      sideMenu: ['daily', 'bonuses', 'upgrades', 'battles', 'faq', 'pf', 'telegram', 'discord'],
      desktopMenu: ['battles', 'upgrades'],
      customLinks: {
        telegram: 'https://t.me/tastydrop',
        discord: 'https://discord.com/invite/2v6x9m2',
      },
      otherGamesLinks: {
        cs: 'https://click.open-case-welcome.com/AvfGy4RN?sub_id1=crosssaleCL&sub_id2=header&sub_id3=DB&sub_id4=DB&sub_id5=FRIENDCL',
      },
    },
    faq: {
      type3: {
        categoryId: 1,
        questionId: 1,
      },
    },
    footer: {
      copyright: 'DotaBoom.gg',
      paymentMethods: ['visa', 'mastercard', 'bitcoin', 'tether', 'ton', 'zen'],
      hideSocials: true,
    },
    legalData: {
      beneficiary: 'Big Corgi Development OÜ',
      registrationNumber: 16252850,
      address: 'Harju maakond, Tallinn, Kesklinna linnaosa, Masina tn 22, 10113',
    },
    isBattlesDisabled: false,
    isChallengesDisabled: false,
    isProvablyFairDisabled: false,
    isUpgradesDisabled: false,
    isTechiesDisabled: true,
    hideBattlesBanner: false,
    hideSecretShop: true,
    hideFreeCasesBanner: false,
    showPacksBanner: false,
    hideHeaderMenuItems: [
      EHeaderMenuItems.BONUS_PAGE,
      EHeaderMenuItems.SECRETSHOP,
      EHeaderMenuItems.UPGRADES,
      EHeaderMenuItems.CONTRACT,
      EHeaderMenuItems.TECHIES,
    ],
    hideProjectSwitcher: true,
    howItWorks: {
      hideStep6: true,
    },
    supportKey: 'wmpPbAPLQ9',
    gtmKey: 'G-MC2MLGF5VT',
    seo: {
      favicon: '/img/favicon/dota-global.ico',
      title: 'DotaBoom.gg - Dota 2 Cases',
      description:
        'DotaBoom.gg is the largest Dota 2 cases site. Buy cases, get skins and cosmetics! Steam deal in 1 minute.',
    },
    statistics: {
      hideElements: [EStatisticItemVariants.ARCANES],
    },
    payment: {
      project: 'Dotaboom',
    },
    techWork: {
      logoClass: 'logo--dota-global',
    },
    redirects: {
      newCases: '/#all-cases',
    },
    profile: {
      hideSteamLogin: true,
    },
    subscriptions: {
      variants: ['email', 'steam'],
      show: false,
    },
    upgrades: {
      priceMaxDifference: 9000,
      progressVars: {
        '--center': '#171A21',
        '--stroke': '#4E5165',
        'rgb': '0 0 0 0 0.564706 0 0 0 0 0.596078 0 0 0 0 0.717647 0 0 0 1 0',
      },
    },
  };
